import React from 'react';

import Layout from '@accrosoft-ltd/vf-careers-site-theme/src/components/layout';

const base64url = require('base64url');

const NotFoundPage = ({ pageContext: { config }, location }) => {
  const siteConfig = config;

  const params = new URLSearchParams(location.search);
  const extraDataEncoded = params ? params.get('ExtraData') : null;
  const extraDataDecoded = extraDataEncoded
    ? base64url.decode(extraDataEncoded)
    : null;

  const extraDataDecodedSplit = extraDataDecoded
    ? extraDataDecoded.split('|')
    : [];

  return (
    <Layout
      displayJACTA={false}
      siteConfig={siteConfig || {}}
      location={location}
      description={
        extraDataDecodedSplit && extraDataDecodedSplit[4]
          ? extraDataDecodedSplit[4]
          : extraDataDecodedSplit && extraDataDecodedSplit[1]
          ? 'Check out this vacancy from ' + extraDataDecodedSplit[1]
          : 'Check out this vacancy.'
      }
      title={
        extraDataDecodedSplit &&
        extraDataDecodedSplit[0] &&
        extraDataDecodedSplit[1]
          ? extraDataDecodedSplit[0] + ' | ' + extraDataDecodedSplit[1]
          : extraDataDecodedSplit && extraDataDecodedSplit[0]
          ? extraDataDecodedSplit[0]
          : 'Loading page...'
      }
    >
      <main id="main">
        <div className="wrapper wrapper--not-mobile">
          <section
            className="page-hero vacs-jobalerts-hero"
            style={{
              backgroundImage:
                'url("https://oaklandcare.com/app/uploads/2019/04/shutterstock_469949942.original-scaled.jpg")',
              // backgroundImage: `url(${contentDataData[1].children[0].children[0][0].value})`,
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div className="u-opacity-layer"></div>
            <div className="page-hero__inner">
              <div className="page-hero__content">
                <div className="col-12 col-lg-8">
                  <div className="loading-panel">
                    <div className="loading-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <span className="loading-text">
                      Please wait... If content is not shown soon then you may
                      have the wrong URL.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </Layout>
  );
};

export default NotFoundPage;
